import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-laredo-texas.png'
import image0 from "../../images/cities/scale-model-of-republic-of-the-rio-grande-museum-in-laredo-texas.png"
import image1 from "../../images/cities/scale-model-of-san-agustin-cathedral-in-laredo-texas.png"
import image2 from "../../images/cities/scale-model-of-border-heritage-museum-in-laredo-texas.png"
import image3 from "../../images/cities/scale-model-of-laredo-convention-&-visitors-in-laredo-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Laredo'
            state='Texas'
            image={image}
            lat='27.5035613'
            lon='-99.50755190000001'
            attractions={ [{"name": "Republic of the Rio Grande Museum", "vicinity": "1005 Zaragoza St, Laredo", "types": ["museum", "point_of_interest", "establishment"], "lat": 27.5020526, "lng": -99.50586140000001}, {"name": "San Agustin Cathedral", "vicinity": "201 San Agustin Ave, Laredo", "types": ["church", "place_of_worship", "point_of_interest", "establishment"], "lat": 27.5025208, "lng": -99.50546129999998}, {"name": "Border Heritage Museum", "vicinity": "810 Zaragoza St, Laredo", "types": ["museum", "point_of_interest", "establishment"], "lat": 27.502329, "lng": -99.50406400000003}, {"name": "Laredo Convention & Visitors", "vicinity": "501 San Agustin Ave, Laredo", "types": ["point_of_interest", "local_government_office", "establishment"], "lat": 27.5043596, "lng": -99.5055122}] }
            attractionImages={ {"Republic of the Rio Grande Museum":image0,"San Agustin Cathedral":image1,"Border Heritage Museum":image2,"Laredo Convention & Visitors":image3,} }
       />);
  }
}